const validateGreekMobilePhone = (value) =>
  value && /^(\+30|0030)?69\d{8}$/.test(value);

const validateGreekLandlinePhone = (value) =>
  value && /^(\+30|0030)?(2)\d{9}$/.test(value);

const validateGreekPhone = (value) =>
  validateGreekMobilePhone(value) || validateGreekLandlinePhone(value);

const validateGreekMobilePhoneNoPrefix = (value) =>
  value && /^69\d{8}$/.test(value);

const validateGreekLandlinePhoneNoPrefix = (value) =>
  value && /^2\d{9}$/.test(value);

const validateGreekPhoneNoPrefix = (value) =>
  validateGreekMobilePhoneNoPrefix(value) ||
  validateGreekLandlinePhoneNoPrefix(value);

export {
  validateGreekMobilePhone,
  validateGreekLandlinePhone,
  validateGreekPhone,
  validateGreekMobilePhoneNoPrefix,
  validateGreekLandlinePhoneNoPrefix,
  validateGreekPhoneNoPrefix,
};
