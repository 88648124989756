import React from 'react';

import ActionsContainer from 'components/ActionsContainer/ActionsContainer';
import LoadingPrimaryButton from 'components/Buttons/LoadingPrimaryButton';
import CustomContactForm from 'components/ContactForm/CustomContactForm';
import Container from 'components/Container/Container';
import CustomCreations from 'components/Icons/Project/CustomCreations';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Title from 'components/Title/Title';

import useRequest from 'hooks/useRequest';

import {
  errorMessage,
  successMessage,
} from 'styles/components/form.module.scss';
import {
  banner,
  pageContainer,
  container,
  text,
  btn,
} from 'styles/pages/custom.module.scss';

const EpikoinoniaPage = () => {
  const { data, loading, error, fetch } = useRequest();
  const submit = (data) => {
    fetch(`/special-orders`, { method: 'post', data });
  };

  return (
    <Layout fullWidth={true} hasCustomSection={false}>
      <Seo title="Ειδικές παραγγελίες - Custom Creations" />
      <div className={banner}>
        <CustomCreations
          style={{ marginBottom: '1rem', maxWidth: '28.5rem' }}
          width="100%"
          fill="#fff"
        />
        <Title isBanner={true}>Ειδικές παραγγελίες</Title>
      </div>
      <Container containerClass={pageContainer}>
        <div className={container}>
          <p className={text}>
            Εκφράστε ελεύθερα τη δημιουργικότητα σας!
            <br />
            Περιγράψτε μας το πώς θα θέλατε να τροποποιήσουμε το κόσμημα της
            αρεσκείας σας:
            <br />- στο μήκος της αλυσίδας
            <br />- στο χρώμα των πετρών
            <br />- στην επιμετάλλωση (επιχρύσωση, επαργύρωση κλπ)
            <br />- στο υλικό κατασκευής (ασήμι, ορείχαλκος κλπ)
            <br />ή πείτε μας την ιδέα σας, για να δημιουργήσουμε με απαράμιλλο
            σχεδιασμό και τεχνική, ένα εντελώς καινούργιο κόσμημα, που θα
            συναντά τις προσδοκίες σας, στο συναρπαστικό αυτό ταξίδι στον
            Κρητικό πολιτισμό.
          </p>
          <CustomContactForm
            onSubmit={submit}
            actions={
              <ActionsContainer>
                <LoadingPrimaryButton
                  buttonClass={btn}
                  type="submit"
                  loading={loading}
                >
                  ΑΠΟΣΤΟΛΗ ΜΗΝΥΜΑΤΟΣ
                </LoadingPrimaryButton>
              </ActionsContainer>
            }
          />
          {error && (
            <p className={errorMessage}>
              Συνέβη κάποιο σφάλμα. Προσπαθήστε ξανά!
            </p>
          )}
          {data && data.name && (
            <p className={successMessage}>
              Η αποστολή ολοκληρώθηκε. Θα επικοινωνήσουμε μαζί σας σύντομα.
            </p>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default EpikoinoniaPage;
