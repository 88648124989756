import React from 'react';

import { primary } from './styles.module.scss';

const PrimaryButton = ({ buttonClass = false, children, ...rest }) => {
  return (
    <button
      className={`${primary}${buttonClass ? ' ' + buttonClass : ''}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
