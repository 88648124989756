// extracted by mini-css-extract-plugin
export var container = "CustomContactForm-module--container--1ydPN";
export var row = "CustomContactForm-module--row--4nPww";
export var rowInner = "CustomContactForm-module--rowInner--dh1Hj";
export var inputContainer = "CustomContactForm-module--inputContainer--2mbjC";
export var areaContainer = "CustomContactForm-module--areaContainer--2KZ2t";
export var label = "CustomContactForm-module--label--1iPQD";
export var input = "CustomContactForm-module--input--MI9J0";
export var area = "CustomContactForm-module--area--2Z3Zp";
export var select = "CustomContactForm-module--select--UE6aq";
export var figure = "CustomContactForm-module--figure--DZsqs";
export var itemContainer = "CustomContactForm-module--itemContainer--1Pjmc";