import debounce from 'lodash/debounce';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Image from 'components/Image';

import { validateGreekPhone } from 'utilities/greekPhoneValidators';
import isValidTerm from 'utilities/isValidTerm';

import api from 'api/app';

import {
  container,
  row,
  rowInner,
  inputContainer,
  areaContainer,
  label,
  input,
  area,
  select,
  figure,
  itemContainer,
} from './CustomContactForm.module.scss';

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className={itemContainer}>
        <figure className={figure}>
          <Image
            filename={props.data.images[0].hash}
            alt={props.data.images[0].alternativeText || props.data.title}
            styles={{ display: 'block' }}
          />
        </figure>
        <div>
          <div>{props.data.title}</div>
          <div>{(props.data.finalPrice / 100).toFixed(2)} €</div>
        </div>
      </div>
    </components.Option>
  );
};

const ContactForm = ({ onSubmit, actions }) => {
  const { register, handleSubmit, errors, control } = useForm();

  const loadOptions = React.useMemo(
    () =>
      debounce((term, callback) => {
        if (isValidTerm(term)) {
          api.get(`/products/search?term=${term}`).then((res) => {
            callback(
              res.data.map((item) => ({
                label: `${item.title} - ${(item.finalPrice / 100).toFixed(
                  2
                )} €`,
                value: item._id,
                ...item,
              }))
            );
          });
        }
      }, 500),
    []
  );

  return (
    <form className={container} noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className={row}>
        <div className={`${rowInner} ${inputContainer}`}>
          <label className={label} htmlFor="name">
            Ονοματεπώνυμο
          </label>
          <input
            className={input}
            id="name"
            name="name"
            type="text"
            aria-label="Επώνυμο"
            ref={register({
              required: 'Το επώνυμο είναι υποχρεωτικό',
            })}
          />
          {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
        </div>
        <div className={`${rowInner} ${inputContainer}`}>
          <label className={label} htmlFor="name">
            Επιλογή προϊόντος
          </label>
          <Controller
            className={select}
            classNamePrefix="react-select-form"
            components={{ Option }}
            name="products"
            as={AsyncSelect}
            loadOptions={loadOptions}
            cacheOptions
            defaultOptions={[]}
            defaultValue={null}
            isMulti
            placeholder="Επιλέξτε προϊόν"
            control={control}
            styles={{
              option: (base, { isDisabled, isFocused, isSelected }) => ({
                ...base,
                minHeight: '2rem',
                color: '#000',
                backgroundColor: isDisabled
                  ? null
                  : isSelected
                  ? '#f3f3f1'
                  : isFocused
                  ? '#f3f3f1'
                  : null,
                ':active': {
                  ...base[':active'],
                  backgroundColor:
                    !isDisabled && (isSelected ? '#f3f3f1' : '#f3f3f1'),
                },
              }),
            }}
            rules={{ required: 'Πρέπει να επιλέξεις τουλάχιστον 1 προϊόν' }}
          />
          {errors.products && (
            <ErrorMessage>{errors.products.message}</ErrorMessage>
          )}
        </div>
      </div>
      <div className={row} style={{ alignItems: 'flex-start' }}>
        <div className={`${rowInner} ${inputContainer}`}>
          <div className={inputContainer}>
            <label className={label} htmlFor="email">
              Email
            </label>
            <input
              name="email"
              type="email"
              aria-label="Email"
              className={input}
              ref={register({
                required: 'Το email είναι υποχρεωτικό',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Λάθος μορφή email',
                },
              })}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
          </div>
          <div className={inputContainer}>
            <label className={label} htmlFor="contactPhone">
              Τηλέφωνο
            </label>
            <input
              className={input}
              id="contactPhone"
              name="contactPhone"
              type="text"
              aria-label="Τηλέφωνο επικοινωνίας"
              ref={register({
                required: 'Το πεδίο Τηλέφωνο είναι υποχρεωτικό',
                validate: (value) =>
                  validateGreekPhone(value) ? true : 'Λάθος μορφή τηλεφώνου',
              })}
            />
            {errors.contactPhone && (
              <ErrorMessage>{errors.contactPhone.message}</ErrorMessage>
            )}
          </div>
        </div>

        <div className={rowInner}>
          <div className={areaContainer}>
            <label className={label} htmlFor="comments">
              Περιγραφή ειδικής παραγγελίας
            </label>
            <textarea
              className={area}
              id="message"
              name="message"
              rows="8"
              cols="40"
              ref={register({
                required: 'Η περιγραφή είναι υποχρεωτική',
              })}
            />
            {errors.message && (
              <ErrorMessage>{errors.message.message}</ErrorMessage>
            )}
          </div>
        </div>
      </div>
      {actions}
    </form>
  );
};

export default ContactForm;
