import React from 'react';
import { VscLoading } from 'react-icons/vsc';

import PrimaryButton from 'components/Buttons/PrimaryButton';

const LoadingPrimaryButton = ({ children, icon = null, loading, ...rest }) => {
  return (
    <PrimaryButton disabled={loading} {...rest}>
      {loading ? <VscLoading className="spin" /> : icon} {children}
    </PrimaryButton>
  );
};

export default LoadingPrimaryButton;
