import { useState } from 'react';

import api from 'api/app';

const useRequest = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetch = async (url, config = {}) => {
    try {
      setLoading(true);
      const response = await api(url, config);
      setError(false);
      setData(response.data);
    } catch (err) {
      setError(err);
      setData({});
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetch };
};

export default useRequest;
